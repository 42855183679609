import React, { useEffect, useState } from 'react'
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom'

import { searchActions } from 'src/contexts/actions/searchActions'
import { AuthorityFileLoaderDataProps } from 'src/contexts/loader'
import { useDispatch } from 'src/contexts/state'
import { useParsedSearchParams } from 'src/utils/searchparams'

import { useSearchTranslation } from '../search/utils'
import { Entity } from './Entities'
import { EntityType } from './Entities/AuthObject'
import { getAuthorityEntityType } from './utils/getAuthorityEntityType'

export function NormOverview() {
  const { searchT } = useSearchTranslation()
  const { authorityfileid } = useParsedSearchParams()
  const dispatch = useDispatch()

  const { hash } = useLocation()

  const navigate = useNavigate()
  const [entityType, setEntityType] = useState<EntityType>()
  const { authItem, numFound } = useLoaderData() as AuthorityFileLoaderDataProps

  const navigation = useNavigation()

  useEffect(() => {
    if (hash === '#authority') {
      const desc = document.getElementById('authorityFileResult')
      desc?.scrollIntoView()
      desc?.focus()
    } else {
      window.scrollTo(0, 0)
    }
  }, [authItem, hash])

  useEffect(() => {
    if (authItem) {
      const entityType = getAuthorityEntityType(authItem.typeName)
      if (!entityType) {
        navigate(`/search?q=%22${authorityfileid}%22`)
        dispatch(searchActions.setAuthorityId(''))
      } else {
        dispatch(searchActions.setAuthorityId(authItem.id))
        setEntityType(entityType)
      }
    }
  }, [authItem])

  useEffect(() => {
    if (authorityfileid) {
      dispatch(searchActions.setAuthorityId(authorityfileid))
    }
  }, [authorityfileid])

  if (navigation.state === 'loading') {
    return <p data-testid="discovery-norm-overview">{searchT('loading')}</p>
  }

  return (
    <Entity
      authItem={authItem}
      numFound={numFound}
      entityType={entityType as EntityType}
    />
  )
}
