import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { hasHighlights } from 'src/components/search/utils'
import { HitWrapper } from 'src/components/shared/ResultView/ResultHits/HitWrapper'
import { Highlighting, HspObjectGroup } from 'src/contexts/discovery'

import { Citations } from './Citations'
import { SearchHitContent } from './SearchHitContent'
import { SearchHitHead } from './SearchHitHead'

const useStyles = makeStyles((theme) => ({
  headline: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
}))

interface Props {
  variant: 'expanded' | 'collapsed' | 'keyData' | 'citations'
  entry: HspObjectGroup
  highlighting: Highlighting
}

export function KeyDataSearch({
  variant,
  entry,
  highlighting,
}: Readonly<Props>) {
  const cls = useStyles()

  const isExpandedOrKeyData = variant === 'expanded' || variant === 'keyData'
  const isExpandedOrCitations =
    variant === 'expanded' || variant === 'citations'

  return (
    <>
      <HitWrapper>
        <>
          <div className={cls.headline}>
            <SearchHitHead hspObjectGroup={entry} open={isExpandedOrKeyData} />
          </div>
          <SearchHitContent hspObjectGroup={entry} open={isExpandedOrKeyData} />
        </>
      </HitWrapper>
      {hasHighlights(entry, highlighting) && (
        <Citations
          hspObjectGroup={entry}
          highlighting={highlighting}
          open={isExpandedOrCitations}
        />
      )}
    </>
  )
}
